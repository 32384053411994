import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { Heading } from '../components/Headings';
import { colors, fonts } from '../components/utilities/theme';
import { Icon } from '../components/svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

const ScreenPrinting = () => {
  const data = useStaticQuery(graphql`
    {
      first: file(relativePath: { eq: "screen-printing-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(relativePath: { eq: "screen-printing-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="screen-printing">
        <Grid>
          <FirstTitle behindText="art services">screen printing</FirstTitle>
          <SectionOneText>
            <Icon name="person" />
            <Paragraph>
              Here at Eon Studios, when you talk to the artist, you're also
              talking to the printer. Michael does the art preparation and
              Patrick does the printing. We both like to be involved in each
              step of the process to help guarantee an excellent product.
            </Paragraph>
          </SectionOneText>
          <SectionOnePic
            fluid={data.first.childImageSharp.fluid}
            alt="patrick holding up a t-shirt"
          />
          <SectionTwoText>
            <Icon name="printer" />
            <ParagraphTwo>
              We use indirect stencils to make our screens which allows us to
              retain an incredible amount of detail. Whether it is tiny
              lettering or complex designs you can expect clean crisp lines.
            </ParagraphTwo>
          </SectionTwoText>
          <SectionThreeText>
            <Icon name="high-quality" />
            <ParagraphThree>
              The advantage of a smaller shop is you can rest assured that your
              concerns are taken seriously and addressed. We show the same
              attention to the client ordering 5 shirts as we do the one
              ordering 7000 shirts.
            </ParagraphThree>
          </SectionThreeText>
          <SectionFourPic
            fluid={data.second.childImageSharp.fluid}
            alt="patrick screen printing close-up"
          />
          <SectionFourText>
            <Icon name="painting" />
            <ParagraphThree>
              We have a 6 color manual printing press that has operated for 36
              years and printed countless garments. We are able print multiple
              colors in perfect registration all while retaining an extensive
              amount of detail.
            </ParagraphThree>
          </SectionFourText>
        </Grid>
      </Layout>
    </motion.div>
  );
};

export default ScreenPrinting;

const Grid = styled.div`
  justify-self: center;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  z-index: 10;
  margin: 15vh 0;
  row-gap: 20vh;
  align-items: center;
  grid-template-areas:
    'title title'
    'section-one-text section-one-pic'
    'section-two-text section-three-text'
    'section-four-pic section-four-text';

  @media (max-width: 1093px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    justify-content: center;
    grid-template-areas:
      'title'
      'section-one-text'
      'section-one-pic'
      'section-two-text'
      'section-three-text'
      'section-four-text'
      'section-four-pic';
  }

  @media (max-width: 1093px) and (orientation: landscape) {
    margin: 30vh 0;
  }
`;

const SectionOneText = styled.section`
  grid-area: section-one-text;
  margin-right: 85px;
  display: grid;
  align-content: start;
  row-gap: 1em;
  justify-items: center;
  min-width: 500px;
  max-width: 600px;

  @media (max-width: 1093px) {
    margin-right: 0;
  }

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const Paragraph = styled.p`
  font-family: ${fonts.secondary};
  font-size: 24px;
  line-height: 1.75;
  max-width: 450px;
`;

const SectionOnePic = styled(Img)<GatsbyImage>`
  grid-area: section-one-pic;
  max-width: 600px;
  border-radius: 2px;
`;

const SectionTwoText = styled.section`
  grid-area: section-two-text;
  background: ${colors.black};
  width: 100%;
  padding: 35px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  display: grid;
  align-content: start;
  row-gap: 1em;
  justify-items: center;
  min-height: 450px;
  max-width: 600px;

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const SectionThreeText = styled(SectionTwoText)`
  grid-area: section-three-text;
  background: ${colors.secondary};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  max-width: 600px;

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const SectionFourPic = styled(Img)<GatsbyImage>`
  grid-area: section-four-pic;
  max-width: 600px;
  border-radius: 2px;
`;

const SectionFourText = styled(SectionThreeText)`
  grid-area: section-four-text;
  background: none;
  max-width: 600px;

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const ParagraphTwo = styled(Paragraph)`
  color: white;
`;

const ParagraphThree = styled(ParagraphTwo)`
  color: ${colors.black};
`;

const FirstTitle = styled(Heading)`
  grid-area: title;
`;
